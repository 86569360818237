.homePage {
  background: #060906;
  background-size: 1920px;
  height: 100vh;
  width: 100vw;

  a {
    color: inherit;
    text-decoration: none;
  }
  #wrapper {
    clear: both;
    width: 100%;
    position: relative;
    // overflow: hidden;
    // background-color: #1b1919;
    background-color: #000;
  }
  #header {
    h1 {
      position: absolute;
      left: 0;
      top: 16px;
      color: #fff;
      z-index: 9;
      font-size: 15;
    }

    position: relative;
    // height: 700px;
    z-index: 99;
    .headerBG {
      z-index: 9;
      position: relative;
      top: 0;
      left: 0;
      right: 0;
      .absoluteWrap {
        position: relative;
        margin: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .text {
          color: red;
          text-align: left;
        }

        .headerMenuRow {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-left: 100px;

          width: 1000px;
          margin-top: 50px;

          .headerMenuIcon {
            margin-left: 100px;
          }

          .menu {
            display: flex;
            width: 100%;
            background: url(../images/nav_menu_background.png);
            button {
              cursor: pointer;
              padding: 10px;
              flex: 1;
              color: #fff;
              background-color: transparent;
              font-size: 16px;
              border-color: #fff;
              border-style: solid none solid solid;

              text-shadow: 1px 1px 2px #000;
              box-shadow: 0px 0px 3px 1px #000000aa inset, 0 4px 4px -4px #000000aa, 0px -2px 2px -2px #000000aa;

              margin: 0px;

              &:last-child {
                border-right-style: solid;
              }
            }
          }
        }

        .contentMediaRow {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 10px;
          width: 100%;
          .headerText {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            position: static;
            margin: 0;
            margin-top: 10px;
            // right: 0;
            color: #fff;
            z-index: 9;
            font-size: 16px;
          }
        }

        .slide1 {
          display: flex;

          // display: none;
          &.active {
            display: list-item;
          }

          .text {
            color: #fff;
            text-align: justify;
            line-height: 1.5;
          }

          .img {
            position: relative;
            right: 140 px;
            width: 820px;
            height: 720px;
          }

          .column {
            display: flex;
            flex-direction: column;

            .text {
              font-size: 20px;
              overflow: visible;
              white-space: nowrap;
              line-height: 2;
            }

            .text2 {
              width: 400px;
              font-size: 14px;
              line-height: 1.7;
            }

            .container {
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              width: 327px;
              height: 250px;

              .headerImage {
                flex-shrink: 0;
                max-width: 327px;
                max-height: 327px;
              }
            }
          }
        }

        .logo {
          position: absolute;
          left: 0;
          top: 512px;
          z-index: 8;
        }

        .nav {
          &.nav-fl:after,
          &.nav-fr:after,
          &.clearfix:after {
            content: '.' !important;
            clear: both !important;
            display: block !important;
            height: 0 !important;
            visibility: hidden !important;
          }
          zoom: 1 !important;
          margin-top: 0;
          position: absolute;
          right: 2px;
          top: 709px;
          list-style: none;

          li {
            display: inline;
          }
        }
        .tel {
          position: absolute;
          left: 0;
          top: 790px;
        }
      }
    }

    #key {
      height: 709px;
      width: 800px;

      position: absolute;
      // left: 0;
      top: 50px;
      right: 50px;

      .slideFade {
        min-height: inherit;
        height: 709px;
      }

      li {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        min-height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  #headerSection2 {
    position: relative;
    background: #060906;
    background-size: 1920px;
    background-position-y: -300px;

    height: 1060px;
    // width: 100vw;
    z-index: 99;

    .absoluteWrap {
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      h1 {
        color: #fff;
        z-index: 9;
        font-size: 28px;
      }

      h2 {
        color: #fff;
        z-index: 9;
        font-size: 16px;
        text-align: justify;
        margin-left: 0px;
        line-height: 1.8;
      }

      h3 {
        font-size: 30px;
        margin-bottom: 10px;
      }

      .row {
        display: flex;
        flex-direction: row;

        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        }
      }
    }
  }

  #headerSection9 {
    .absoluteWrap {
      background: #060906 url(../images/page1/section9/background.png) no-repeat;
    }
  }

  .whiteBanner {
    padding: 10px 30px 10px 30px;
    text-align: center;
    background: #382a16 url(../images/page1/brown_banner.png) repeat;

    background-size: contain;
    position: relative;
    button {
      background-color: transparent;
    }

    .media {
      position: absolute;
      right: 20px;
      bottom: 10px;
      display: flex;
      justify-content: flex-end;
      button {
        border: none;
      }
    }

    .contact {
      position: absolute;
      left: 20px;
      bottom: 20px;
      text-align: left;

      h5 {
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        margin: 5px;
      }
    }

    h3 {
      font-weight: 600;
      color: #fff;
    }

    .underline {
      position: relative;
      top: -20px;
    }

    p {
      font-size: 12px;
      color: #fff;
      margin: 2px;
      font-weight: 600;
    }

    h6 {
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      margin: 5px 0 0 0;
    }
    .flex {
      margin: auto;
      width: 1100px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      button {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        color: #fff;
        position: relative;
        border: none;
        background-color: transparent;

        &:first-of-type {
          &:after {
            content: none;
          }
        }
        &:after {
          content: '';
          background: #999;
          position: absolute;
          bottom: 6px;
          left: 0;
          height: 40%;
          width: 1px;
        }
      }
    }
  }

  .session {
    position: relative;
    // width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    padding: 30px;

    // image row
    .productImgRow {
      display: flex;
      align-items: start;
    } 

    // hint
    .remark {
      color: #FFFFFF;
      font-size: 16;
      zoom: 0.5;
    }

    h2,
    h4 {
      color: #b48958;
      font-size: 18px;
      letter-spacing: 0;
    }

    h3,
    h5 {
      color: #fff;
      font-size: 16px;
      letter-spacing: 0;
    }

    .framedText {
      width: 265px;
    }

    h2 {
      font-size: 24px;
    }
    h3 {
      font-size: 22px;
    }
    img {
      width: 100%;
    }

    .absoluteWrap {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .textWrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2,
      h4 {
        color: #b48958;
      }

      h3,
      h5 {
        color: #fff;
      }

      .framedText {
        width: 265px;
      }
    }

    .framedText {
      padding: 5px 5px 10px 5px;
      border: 1px solid #b48958;
      margin-top: 5px;
      text-align: center;
    }
  }

  #session1 {
    text-align: left;
    // height: 880px;
  }

  #session2 {
    text-align: left;
    // height: 850px;
  }

  #session3 {
    text-align: left;
    // height: 720px;
    // margin-left: 140px;
  }

  #session4 {
    text-align: left;
    padding-bottom: 150px;
  }

  .menuBanner {
    // margin-top: 100px;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #060906 url(../images/page2/menuBanner.png) no-repeat;
    background-size: cover;
  }

  .session5 {
    position: relative;
    flex-direction: column;
    // width: 1200px;
    margin: auto;
    color: #fff;

    .framedText {
      // margin: 20px 50px 0 50px;
      width: 300px;
    }

    img {
      width: 380px;
      // height: 520px;
    }

    h1 {
      font-size: 30px;
      margin-top: 60px;
      margin-bottom: 30px;
    }

    h2 {
      color: #b48958;
      text-align: center;
    }

    .recommend {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;

      .description {
        margin-bottom: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        img,
        p {
          width: 60%;
        }

        img {
          object-fit: contain;
        }

        pre {
          // padding-top: 30px;
          // width: 340px;
        }

        h2 {
          padding-top: 20px;
        }

        .center {
          width: 100%;
          text-align: center;
        }
      }
    }

    .titlewrapper {
      margin: auto;
      margin-top: 60px;
      padding: 10px 60px;
      background-color: rgb(20, 19, 19);
      border: 1px solid #b48958;
      border-radius: 10px;
      font-size: 24px;
      color: #b48958;

      .title {
        width: fit-content;
      }
    }

    .items {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      width: 100%;
      background: #060906 url(../images/background/homepage_background_1_reflected.png) repeat-x;
      background-position: bottom;
      background-size: contain;

      .item {
        width: 100%;
        // padding-left: 30px;

        p,
        pre,
        h1,
        h2 {
          // width: 380px;
        }

        margin-top: 0px;
        margin-bottom: 0px;
      }

      h2 {
        margin-top: 40px;
      }
    }
  }
}

.navMenu {
  // padding: 10px 5px 10px 5px;
  // overflow: auto;

  .navButton {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 70px;
    color: #fff;
    border: 1px #fff solid;
    border-bottom: none;
    background-image: url('../images/mobile/nav_btn_background.png');
    background-size: cover;
    text-shadow: 1px 1px 2px #00000044;
    box-shadow: 0px 0px 3px 1px #00000044 inset, 0 4px 4px -4px #00000044, 0px -2px 2px -2px #00000044;

    p {
      font-weight: 600;
      font-size: 20px;
    }
  }
}
.menuButton {
  position: fixed;
  top: 20px;
  right: 5px;
  width: 7%;
  padding-bottom: 7%;
  background-image: url('../images/mobile/menuBtn.png');
  background-size: contain;
  z-index: 999;
}
