.menuPage {
  background: #060906 url(../images/background/homepage_background_1.png) repeat-x;
  background-size: 1920px;
  height: 100vh;
  width: 100vw;

  a {
    color: inherit;
    text-decoration: none;
  }

  h1 {
    color: #fff;
    letter-spacing: 0px;
  }

  h2 {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin: 5px;
    letter-spacing: 0px;
  }

  h3 {
    font-size: 28px;
    font-weight: 400;
    margin: 0;
    line-height: 1.3;
    letter-spacing: 0px;
  }
  pre {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    line-height: 1.3;
    letter-spacing: 0px;
  }
  h6 {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  p {
    color: #fff;
  }

  #wrapper {
    clear: both;
    overflow: hidden;
  }

  .absoluteWrap {
    // width: 100%;

    position: relative;
    margin: auto;
  }
  .headerBG {
    position: relative;
    width: 1200px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    // background: #fff url(../images/background/homepage_background_1.png) no-repeat;
    // background: #060906 url(../images/background/homepage_background_1.png) repeat-x;
    // background-size: 1440px;

    #header {
      h1 {
        position: absolute;
        left: 0;
        top: 16px;
        color: #fff;
        font-size: 15;
      }
      position: relative;
      height: 810px;

      .headerMenuRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: 100px;

        width: 1000px;
        margin-top: 50px;

        .headerMenuIcon {
          margin-left: 100px;
        }

        .menu {
          display: flex;
          width: 100%;
          background: url(../images/nav_menu_background.png);
          button {
            cursor: pointer;
            padding: 10px;
            flex: 1;
            color: #fff;
            background-color: transparent;
            font-size: 16px;
            border-color: #fff;
            border-style: solid none solid solid;

            text-shadow: 1px 1px 2px #000;
            box-shadow: 0px 0px 3px 1px #000000aa inset, 0 4px 4px -4px #000000aa, 0px -2px 2px -2px #000000aa;

            &:last-child {
              border-right-style: solid;
            }
          }
        }
      }
      .contentMediaRow {
        display: flex;
        // margin: 0 140px;
        justify-content: flex-end;
        margin-top: 20px;
        margin-bottom: 10px;
        .headerText {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 1200px;
          position: static;
          margin: 0;
          margin-top: 10px;
          margin-bottom: 10px;
          // right: 0;
          color: #fff;
          z-index: 9;
          font-size: 18px;
        }
      }
      .logo {
        position: absolute;
        // left: 140px;
        left: 0;
        top: 50px;
      }

      .menuLogo1 {
        position: absolute;
        top: 400px;
        width: 100%;
        margin: auto;
      }
      .menuLogo1_5 {
        position: absolute;
        top: 530px;
        width: 100%;
        margin: auto;
      }
      .menuLogo2 {
        width: 100%;
        position: absolute;
        top: 600px;
        margin: auto;
      }
    }
  }

  .session {
    position: relative;

    .textWrapper {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      h2,
      h4 {
        color: #b48958;
      }

      h3,
      h5 {
        color: #fff;
      }

      .framedText {
        width: 265px;
      }
    }

    .framedText {
      padding: 5px 5px 10px 5px;
      border: 1px solid #b48958;
      margin-top: 5px;
      text-align: center;
    }
  }

  #setSession {
    text-align: left;
    display: flex;
    align-items: start;
    margin-bottom: 80px;

    .rightHalfWrapper {
      display: flex;
      align-items: start;
      flex-direction: column;
      margin-top: 30px;

      .productImgRow {
        display: flex;
        align-items: start;

        .productImg {
          width: 510px;
        }
      }

      .remark{
        color: #FFFFFF;
        text-align: 'left';
        font-size: 8;
        zoom: 0.7;
      }

      
    }
  }

  #session3 {
    text-align: left;
    height: 490px;
    margin-bottom: 50px;

    img {
      position: absolute;
      left: 600px;
      width: 450px;
      top: 10px;
      // margin-right: -150px;
    }
  }

  .menuBanner {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #060906 url(../images/page2/menuBanner.png) no-repeat;
    background-size: cover;
  }

  .session5 {
    position: relative;
    width: 1200px;
    margin: auto;
    color: #fff;

    .framedText {
      // margin: 20px 50px 0 50px;
      width: 300px;
    }

    img {
      width: 380px;
      height: 520px;
    }

    h1 {
      font-size: 30px;
      margin-top: 60px;
      margin-bottom: 30px;
    }

    h2 {
      color: #b48958;
    }

    .recommend {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;

      .description {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          height: 400px;
        }
        img,
        p {
          width: 380px;
        }

        pre {
          padding-top: 30px;
          // width: 340px;
        }

        h2 {
          padding-top: 20px;
          width: 400px;
          text-align: center;
        }

        .center {
          width: 100%;
          text-align: center;
        }
      }
    }

    .title {
      margin-top: 60px;
    }

    .items {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
      margin: 0 -30px;

      .item {
        width: 300px;
        padding-left: 30px;

        p,
        pre,
        h1,
        h2 {
          width: 380px;
        }

        h2 {
          font-size: 26px;
          margin-left: 0px;
          font-weight: 500;
        }

        margin-top: 0px;
        margin-bottom: 0px;
      }

      h2 {
        margin-top: 40px;
      }
    }
  }

  .whiteBanner {
    margin-top: 60px;
    padding: 10px 30px 2px 30px;
    text-align: center;
    background: #fff url(../images/page2/whiteBanner.png) no-repeat;
    background-size: cover;
    position: relative;
    button {
      background-color: transparent;
    }

    .media {
      position: absolute;
      right: 20px;
      bottom: 10px;
      display: flex;
      justify-content: flex-end;
      button {
        border: none;
      }
    }

    .contact {
      position: absolute;
      left: 20px;
      bottom: 20px;
      text-align: left;

      h5 {
        font-size: 12px;
        font-weight: 600;
        color: #000;
        margin: 10px;
      }
    }

    h3 {
      margin-bottom: 20px;
      font-weight: 600;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      color: #000;
      margin: 2px;
    }

    h6 {
      font-size: 10px;
      font-weight: 600;
      color: #000;
      margin: 5px 0 0 0;
    }
    .flex {
      margin: auto;
      width: 1100px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      button {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        color: black;
        position: relative;
        border: none;
        background-color: transparent;

        &:first-of-type {
          &:after {
            content: none;
          }
        }
        &:after {
          content: '';
          background: #999;
          position: absolute;
          bottom: 6px;
          left: 0;
          height: 40%;
          width: 1px;
        }
      }
    }
  }
}
