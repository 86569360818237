.homePage {
  background: #060906;
  background-size: 1920px;
  height: 100vh;
  width: 100vw;

  a {
    color: inherit;
    text-decoration: none;
  }
  #wrapper {
    clear: both;
    width: 100%;
    position: relative;
    // overflow: hidden;
    background-color: #1b1919;
  }
  #header {
    h1 {
      position: absolute;
      left: 0;
      top: 16px;
      color: #fff;
      z-index: 9;
      font-size: 15;
    }

    position: relative;
    height: 946px;
    z-index: 99999;
    .headerBG {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 930px;
      .absoluteWrap {
        width: 1200px;
        position: relative;
        margin: auto;
        height: 100%;
        .headerMenuRow {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin-left: 100px;

          width: 1000px;
          margin-top: 50px;

          .headerMenuIcon {
            margin-left: 100px;
          }

          .menu {
            display: flex;
            width: 100%;
            background: url(../images/nav_menu_background.png);
            button {
              cursor: pointer;
              padding: 10px;
              flex: 1;
              color: #fff;
              background-color: transparent;
              font-size: 16px;
              border-color: #fff;
              border-style: solid none solid solid;

              text-shadow: 1px 1px 2px #000;
              box-shadow: 0px 0px 1px 1px #000000aa inset, 0 1px 1px -1px #000000aa, 0px -2px 2px -2px #000000aa;

              margin: 0px;

              &:last-child {
                border-right-style: solid;
              }
            }
          }
        }

        .contentMediaRow {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          margin-bottom: 10px;
          .headerText {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 1200px;
            position: static;
            margin: 0;
            margin-top: 10px;
            // right: 0;
            color: #fff;
            z-index: 9;
            font-size: 16px;
          }
        }

        .slide1 {
          display: flex;

          // display: none;
          &.active {
            display: list-item;
          }

          .text {
            color: #fff;
            text-align: justify;
            line-height: 1.5;
          }

          .img {
            position: relative;
            right: 140 px;
            width: 820px;
            height: 720px;
          }

          .column {
            display: flex;
            flex-direction: column;

            .text {
              font-size: 20px;
              overflow: visible;
              white-space: nowrap;
              line-height: 2;
            }

            .text2 {
              width: 400px;
              font-size: 14px;
              line-height: 1.7;
            }

            .container {
              display: flex;
              justify-content: center;
              align-items: center;
              overflow: hidden;
              width: 327px;
              height: 250px;

              .headerImage {
                flex-shrink: 0;
                max-width: 327px;
                max-height: 327px;
              }
            }
          }
        }

        .logo {
          position: absolute;
          left: 0;
          top: 512px;
          z-index: 8;
        }

        .nav {
          &.nav-fl:after,
          &.nav-fr:after,
          &.clearfix:after {
            content: '.' !important;
            clear: both !important;
            display: block !important;
            height: 0 !important;
            visibility: hidden !important;
          }
          zoom: 1 !important;
          margin-top: 0;
          position: absolute;
          right: 2px;
          top: 709px;
          list-style: none;

          li {
            display: inline;
          }
        }
        .tel {
          position: absolute;
          left: 0;
          top: 790px;
        }
      }
    }

    #key {
      height: 709px;
      width: 800px;

      position: absolute;
      // left: 0;
      top: 50px;
      right: 50px;

      .slideFade {
        min-height: inherit;
        height: 709px;
      }

      li {
        background-repeat: no-repeat;
        background-position: center top;
        background-size: cover;
        min-height: inherit;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  #headerSection2 {
    position: relative;
    background: #060906;
    background-size: 1920px;
    background-position-y: -300px;

    height: 1060px;
    width: 100vw;
    z-index: 99999;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      pre {
        zoom: 0.5;
        font-size: 18px;
        line-height: 1.8;
        margin: 0px;
        text-align: left;
      }

      h1 {
        color: #fff;
        z-index: 9;
        font-size: 28px;
      }

      h2 {
        color: #fff;
        z-index: 9;
        font-size: 16px;
        text-align: justify;
        margin-left: 0px;
        line-height: 1.8;
      }

      h3 {
        font-size: 30px;
        margin-bottom: 10px;
      }

      .row {
        display: flex;
        flex-direction: row;

        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        }
      }
    }
  }

  #headerSection3 {
    position: relative;
    height: 700px;
    background-size: 1920px;
    background: #bea47c;

    z-index: 99999;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;
      padding-top: 50px;
      z-index: 999;

      h1 {
        color: #fff;
        z-index: 9;
        font-size: 18px;
      }

      .headerImage {
        margin-top: 20px;
      }

      h2 {
        color: #fff;
        z-index: 9;
        font-size: 18px;
      }

      h3 {
        color: #633920;
        font-size: 13px;
        text-align: left;
        margin: 0px;
        font-weight: 900;
        line-height: 1.5;
      }

      .row {
        display: flex;
        flex-direction: row;

        .container {
          width: 510.675px;
          height: 343px;
          background-size: 510.675px 343px;
        }

        .column {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          text-align: left;
        }
      }
    }
  }

  #headerSection4 {
    position: relative;
    height: 340px;
    background-color: #090b0b;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;

      .textImg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
    }
  }

  #headerSection5 {
    height: 103px;
    // height: 150px;
    background-size: 3000px 140px;

    .btn {
      height: 93px;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      flex-direction: column;
      font-size: 22px;
      margin-top: 5px;
      margin-bottom: 5px;
      line-height: 1.2;
    }

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
    }
  }

  #headerSection6 {
    position: relative;
    z-index: 99999;
    background-color: #090b0b;

    h3 {
      color: #464849;
      margin: 1px;
      zoom: 0.5;
      font-size: 20px;
    }

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;

        .img {
          position: relative;
        }
      }
    }
  }

  #headerSection7 {
    position: relative;
    height: 150px;
    background: url(../images/page1/section7/background.png);

    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 99999;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
    }
  }

  #headerSection8 {
    position: relative;
    z-index: 99999;
    background-color: #1b1919;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;

      .header {
        font-size: 38px;
        color: #fff;
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 50px;

        .column {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .text {
            color: #c2a26b;
            font-size: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  #headerSection9 {
    position: relative;
    height: 130px;
    z-index: 99999;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: center;
      height: 100%;
    }
  }

  #headerSection10 {
    position: relative;
    z-index: 99999;

    .base {
      position: relative;
      z-index: 99999;
      background-color: #000;

      .absoluteWrap {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;

        .row {
          display: flex;
          flex-direction: row;

          .column {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: left;
            overflow: hidden;
            width: 380px;

            .imgText {
              display: flex;
              align-items: center;
              justify-content: center;
              color: #c2a26b;
              z-index: 999;
              width: 100%;
              text-align: center;
              font-size: 24px;
              margin-top: 20px;
            }

            .img {
              position: relative;
              zoom: 1.15;
            }
            .text {
              line-height: 2;
              color: #fff;
              margin: 5px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }

  #headerSection11 {
    position: relative;
    z-index: 99999;
    // padding-bottom: 100px;
    background-color: #1b1919;
    // margin-bottom: 60px;

    .absoluteWrap {
      width: 1200px;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-direction: column;
      flex-wrap: wrap;
      align-items: flex-start;

      .header {
        font-size: 32px;
        color: #c2a26b;
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-content: space-between;

        .column {
          width: 260px;
          color: #fff;
          display: flex;
          justify-items: flex-start;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: justify;
          margin-right: 20px;
          line-height: 1.8;
        }
      }
    }
  }

  .whiteBanner {
    padding: 10px 30px 10px 30px;
    text-align: center;
    background: #382a16 url(../images/page1/brown_banner.png) no-repeat;

    background-size: cover;
    position: relative;
    button {
      background-color: transparent;
    }

    .media {
      position: absolute;
      right: 20px;
      bottom: 10px;
      display: flex;
      justify-content: flex-end;
      button {
        border: none;
      }
    }

    .contact {
      position: absolute;
      left: 20px;
      bottom: 20px;
      text-align: left;

      h5 {
        font-size: 11px;
        font-weight: 600;
        color: #fff;
        margin: 5px;
      }
    }

    h3 {
      font-weight: 600;
      color: #fff;
    }

    .underline {
      position: relative;
      top: -20px;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      color: #fff;
      margin: 2px;
    }

    h6 {
      font-size: 10px;
      font-weight: 600;
      color: #fff;
      margin: 5px 0 0 0;
    }
    .flex {
      margin: auto;
      width: 1100px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 10px;
      button {
        font-size: 14px;
        font-weight: 600;
        width: 100%;
        color: #fff;
        position: relative;
        border: none;
        background-color: transparent;

        &:first-of-type {
          &:after {
            content: none;
          }
        }
        &:after {
          content: '';
          background: #999;
          position: absolute;
          bottom: 6px;
          left: 0;
          height: 40%;
          width: 1px;
        }
      }
    }
  }
}
