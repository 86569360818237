@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500&family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Serif+JP:wght@200;300;400;500&display=swap');
body {
  margin: 0;
  text-align: center;
  background-color: #060906;
}
a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

input,
p {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--body-font-size);
}

ul {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
}

hr {
  border: none;
  height: 10px;
  margin: 10px 0;
}

:root {
  --font-size-h1: 32px;
  --font-size-h2: 26px;
  --font-size-h3: 24px;
  --font-size-h4: 20px;
  --font-size-h5: 18px;
  --font-size-h6: 16px;
  --body-font-size: 16px;
}
h1 {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--font-size-h1);
  font-weight: 900;
  line-height: 1.5;
  margin: 0px;
  letter-spacing: 1.5px;
}

h2 {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--font-size-h2);
  margin: 5px;
  font-weight: 500;
  line-height: 1.5;
  margin: 5px;
  letter-spacing: 1.5px;
}

h3 {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--font-size-h3);
  font-weight: 500;
  line-height: 2;
  margin: 5px;
  letter-spacing: 0;
}

h4 {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--font-size-h4);
  font-weight: 500;
  margin: 5px;
  letter-spacing: 0;
}

h5 {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--font-size-h5);
  font-weight: 400;
  margin: 5px;
  letter-spacing: 1px;
  line-height: 1.6;
}

h6 {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
  font-size: var(--font-size-h6);
  font-weight: 400;
  margin: 5px;
  letter-spacing: 1px;
  line-height: 1.6;
}

pre {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif;
}

svg {
  width: 1em;
}

// select {
//   font: inherit;
//   color: inherit;
// }

// img {
//   width: 100%;
//   max-width: 100%;
// }

// /**
//  * 1. Change the font styles in all browsers.
//  * 2. Remove the margin in Firefox and Safari.
//  */

button,
input,
optgroup,
select,
textarea,
label {
  font-family: 'Frank Ruhl Libre', 'Noto Serif JP', serif; /* 1 */
  //   font-size: 100%; /* 1 */
  //   line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
    outline: 0;
    background-color: transparent;
    border: 0;
    padding: 0;
    &:active,
    &:hover,
    &:focus {
      outline: none;
    }
}

// button {
//   cursor: pointer;
//   white-space: nowrap;
// }

// /**
// 	* Show the overflow in IE.
// 	* 1. Show the overflow in Edge.
// 	*/

// button,
// input {
//   /* 1 */
//   overflow: visible;
// }

// /**
// 	* Remove the inheritance of text transform in Edge, Firefox, and IE.
// 	* 1. Remove the inheritance of text transform in Firefox.
// 	*/

// button,
// select {
//   /* 1 */
//   text-transform: none;
// }

// /**
// 	* Correct the inability to style clickable types in iOS and Safari.
// 	*/

// button,
// [type='button'],
// [type='reset'],
// [type='submit'] {
//   -webkit-appearance: button;
// }

// input,
// button {
//   color: inherit;
//   font: inherit;
// }

// * {
//   outline: none;
//   box-sizing: border-box;
//   &::before {
//     box-sizing: border-box;
//   }
//   &::after {
//     box-sizing: border-box;
//   }
// }
